import { createApp } from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import router from './router'
import is from 'is_js'
import VueGtag from "vue-gtag";



let app = createApp(App)
let conf = app.config.globalProperties
conf.$is = is;

app
	.use(VueGtag, {
		config: { 
			id: "UA-120099360-2",
			params: {
				anonymize_ip: true
			}
		}
	})
	.use(router)
	.use(Quasar, quasarUserOptions).mount('#app')

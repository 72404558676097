import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/AccueilView.vue'
import $ from 'jquery'

const routes = [
  {
    path: '/',
    name: 'accueil',
    component: HomeView
  },
  {
    path: '/permis/:slug',
    name: 'permis',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PermisView.vue')
  },
  {
    path: '/pense-bete-tv',
    name: 'pense-bete-tv',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PenseBeteTv.vue')
  },
  {
    path: '/espace-pedagogique',
    name: 'espace-pedagogique',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/EspacePedagogique.vue')
  },
  {
    path: '/a-propos',
    name: 'a-propos',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/APropos.vue')
  },
  {
    path: '/contactez-nous',
    name: 'contactez-nous',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactezNous.vue')
  },
  {
    path: '/mentions-legales',
    name: 'mentions-legales',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MentionsLegales.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('../views/NotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  canReuse: false,
  routes,
  scrollBehavior() {
    // always scroll to top
    let top = 0
    let hash = window.location.hash
    if( hash )
    {
      top = $(hash).offset().top + -45
    }

    return { top: top }
  },
})

export default router

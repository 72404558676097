<template>
  <div class="page flex wrap column items-strech">
    <section style="background: #f9f5f1;">
      <div class="container flex row">
        <div class="col-50 padding">
          <h1 class="logo"><img src="../assets/images/logo.png" alt="PENSE-BÊTE"></h1>
          <p>
            Votre enfant insiste pour adopter un animal de compagnie&thinsp;?
            Vous avez déjà adopté&thinsp;? <br>
            Découvrez ensemble les questions à se poser avant d'adopter
            et comment répondre aux besoins de son animal préféré&thinsp;!
          <!-- <q-btn color="primary" label="Permis de protéger" /> -->
          </p>
        </div>
        <div class="col-50 flex">
          <swiper
            :modules="modules"
            :autoplay="{
                delay: 3000,
              }"
            effect="fade"
            class="bg-round-right slider-head">
            <swiper-slide :style="{'background-image':
            'url('+ require('../assets/images/accueil-header-01.jpg') +')' }"></swiper-slide>
            <swiper-slide :style="{'background-image':
            'url('+ require('../assets/images/accueil-header-02.jpg') +')' }"></swiper-slide>
            <swiper-slide :style="{'background-image':
            'url('+ require('../assets/images/accueil-header-03.jpg') +')' }"></swiper-slide>
            <swiper-slide :style="{'background-image':
            'url('+ require('../assets/images/accueil-header-04.jpg') +')' }"></swiper-slide>
          </swiper>
        </div>
      </div>
    </section>

    <section>
      <div class="container flex row">
        <div class="col-50 flex flex-center padding">
          <img src="../assets/images/illu-chien-chat.jpg">
        </div>
        <div class="col-50 padding">
          <h2><img src="../assets/images/c-est-quoi.png">Pense-bête, c’est quoi&thinsp;?</h2>
          <p>
            Pense-bête est un outil pédagogique de la Région Wallonne pour conscientiser les enfants de 8 et 12 ans au bien-être de leur (actuel ou futur) animal de compagnie et des animaux qu’ils rencontrent au quotidien. Chaque enfant peut passer le test de son choix et décrocher son « permis d’adopter »&thinsp;!
            <br><br>
            <q-btn color="primary" to="#home-permis" label="Permis d’adopter" />
            <!-- <q-btn color="primary" label="Permis de protéger" /> <q-btn color="primary" label="Pour les enseignants" /> -->
          </p>
          <img class="illu-lapin" src="../assets/images/illu-lapin.png">
        </div>
      </div>
    </section>

    <section class="yellow-bg">
      <div class="container flex row">
        <div class="col-50 flex justify-end">
          <div class="bg-round-left permis-general"></div>
        </div>
        <div class="col-50 padding">
          <h2><img src="../assets/images/mieux-connaitre-picto.png">Découvrez, pour commencer,  le «&thinsp;Permis d’adopter général&thinsp;»&thinsp;!</h2>
          <p>Adopter un lapin, un rat, un canari, ou encore un serpent&thinsp;? Une bonne idée&thinsp;? Mettons-nous dans la tête des NAC et des animaux qui cohabitent avec nous au quotidien, comme les insectes, ou les pigeons, pour mieux répondre à leurs besoins.</p>

          <q-btn color="primary" to="/permis/general" label="Passer mon permis général" />

        </div>
      </div>
    </section>

    <!-- <section class="yellow-bg">
      <div class="container flex row">
        <div class="col-50 flex justify-end">
          <div class="bg-round-left mieux-connaitre"></div>
        </div>
        <div class="col-50 padding">
          <h2><img src="../assets/images/mieux-connaitre-picto.png">Apprendre à « penser bête » avec les enfants&thinsp;!</h2>
          <p>Conduire sans avoir étudié le code de la route ? Nous risquons de faire des dégâts ! C’est pourquoi nous proposons des vidéos et des fiches explicatives gratuites et téléchargeables pour aider les enfants à réussir leur permis.</p>

          <q-btn color="primary" to="/pense-bete-tv/#intro" label="Vidéothèque" />

        </div>
      </div>
    </section> -->

    <section>
      <div class="container" id="home-permis">
        <div class="col-center padding">
          <h2><img class="quiz-picto" src="../assets/images/quiz-picto.png">Choisissez votre<br> «&thinsp;Permis d’adopter&thinsp;»&thinsp;!</h2>
          <p>
            Cliquez sur votre animal préféré et répondez avec votre enfant à un quiz de 10 questions.
          </p>
          <div class="permis-gallery flex">
            <div>
              <router-link to="permis/chien"><img src="../assets/images/bouton-chien.jpg" alt="chien"></router-link>
            </div>
            <div>
              <router-link to="permis/chat"><img src="../assets/images/bouton-chat.jpg" alt="chat"></router-link>
            </div>
            <div>
              <router-link to="permis/hamster"><img src="../assets/images/bouton-hamster.jpg" alt="hamster"></router-link>
            </div>
            <div>
              <router-link to="permis/poisson-rouge"><img src="../assets/images/bouton-poisson.jpg" alt="poisson"></router-link>
            </div>
            <div>
              <router-link to="permis/lapin"><img src="../assets/images/bouton-lapin.jpg" alt="lapin"></router-link>
            </div>
            <div>
              <router-link to="permis/cheval"><img src="../assets/images/bouton-cheval.jpg" alt="cheval"></router-link>
            </div>
            <div>
              <router-link to="permis/poule"><img src="../assets/images/bouton-poule.jpg" alt="poule"></router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="yellow-bg">
      <div class="container flex row">
        <div class="col-50 padding">
          <h2><img src="../assets/images/pense-bete-TV-picto.png">Pense-bête TV</h2>
          <p>
            Des vidéos amusantes pour en savoir plus sur vos animaux préférés. Regardez-les à la maison, en balade ou en classe, avec les enfants et passez votre "permis d'adopter" les doigts dans le nez ! <br><br>
            <q-btn color="primary" to="/pense-bete-tv/#intro" label="Vidéothèque" />
          </p>
        </div>
        <div class="col-50 flex justify-start">
          <div class="bg-round-right pense-bete-TV-image"></div>
        </div>
      </div>
    </section>

    <section class="yellow-2-bg">
      <div class="container flex row">
        <div class="col-50 flex justify-end">
          <div class="bg-round-left prof-photo"></div>
        </div>
        <div class="col-50 padding">
          <h2><img src="../assets/images/prof-picto.png">Vous êtes prof&thinsp;?</h2>
          <p>
            Vous souhaitez sensibiliser vos élèves entre 8 et 12 ans au bien-être de l’animal de compagnie ? Vous cherchez une chouette activité pour terminer l’année scolaire&thinsp;? Pense-bête vous propose un scénario pédagogique et des fiches explicatives pour réaliser une activité en toute autonomie. <br><br>
            <q-btn color="primary" label="Espace Pédagogie" to="espace-pedagogique" />
          </p>
        </div>
      </div>
    </section>

    <section class="yellow-bg">
      <div class="container flex row">
        <div class="col-50 padding">
          <h2><img src="../assets/images/pense-bete-TV-picto.png">Tu likes ou pas ?</h2>
          <p>
            Découvrez notre nouveau thème, «&nbsp;Tu likes ou pas&nbsp;?&nbsp;», avec vos enfants ou vos élèves, pour bien réfléchir avant de liker sur les réseaux sociaux&nbsp;!<br><br>
            <q-btn color="primary" to="/pense-bete-tv/#likes" label="Vidéothèque" />
          </p>
        </div>
        <div class="col-50 flex justify-start">
          <div class="bg-round-right bg-like-ou-pas"></div>
        </div>
      </div>
    </section>

    <section>
      <div class="container">
        <div class="col-center padding">
          <h2><img class="quiz-picto" src="../assets/images/picto-FAQ.png" >FAQ</h2>
          <br><br>
          <q-list class="text-left" bordered >
                <q-expansion-item
                  group="faq"
                  label="Comment utiliser Pense-bête ?"
                >
                  <q-card>
                    <q-card-section>
                      <p>
                        L'enfant répond au quiz de son choix de manière individuelle et intuitive. Pour chaque question, il dispose d’un indice. Après avoir obtenu son résultat, il peut visionner les vidéos de « Pense-bête TV » pour recevoir des conseils précieux. Il peut répondre au quiz une 2e fois,  pour réussir son permis ou améliorer son score ! Le permis peut être ensuite téléchargé et imprimé avec son prénom. <br><br>

                        Pour chaque permis, les parents, animateurs ou instituteurs bénéficient de fiches explicatives qui peuvent être lues avec les enfants. Ces fiches permettent de comprendre les bonnes et les mauvaises réponses des quiz. <br><br>

                        Enfin, un scénario pédagogique est proposé aux instituteurs qui souhaitent organiser une activité plus approfondie en classe. </p>
                    </q-card-section>
                  </q-card>
                </q-expansion-item>

                <q-separator />

                <q-expansion-item
                  group="faq"
                  label="Pourquoi un « Permis d’adopter » ? "
                >
                  <q-card>
                    <q-card-section>
                      <p>
                        À partir de 18 ans, chaque adulte dispose automatiquement d’un « Permis de détention » pour adopter un animal de compagnie en Région wallonne. Permis qui peut être retiré en cas de maltraitance. Et si nous nous informions davantage avec les enfants sur nos animaux préférés ? Et si nous passions un test amusant avec eux avant de « prendre la route » ?<br><br>

                        Comme nous étudions le code de la route avant de conduire, Pense-bête propose aux enfants de 8 à 12 ans de se questionner avant d’adopter un animal de compagnie, en répondant à un quiz de 10 questions. <br><br>

                        Réussir son permis, c’est prendre la décision d’adopter ou de ne pas adopter en toute conscience et en toute connaissance. C’est aussi progresser dans l’éducation de son animal de compagnie, s’il est déjà parmi nous, pour un mieux vivre tous ensemble !
                      </p>
                    </q-card-section>
                  </q-card>
                </q-expansion-item>

                <q-separator />

                <q-expansion-item
                  group="faq"
                  label="Pourquoi « Pense-bête » ? "
                >
                  <q-card>
                    <q-card-section>
                      <p>
                        Penser « bête », c’est changer de paire de lunettes pour tenter de se mettre dans la tête de son animal préféré ! Choisir le plus beau panier pour son chien, l’habiller, lui parler, fêter son anniversaire, lui offrir un grand jardin ou prendre un deuxième chien pour qu’il ait de la compagnie. Sommes-nous sur le bon chemin ? <br><br>

                        L’anthropomorphisme, c’est l’attribution de comportements humains à un animal. Souvent, nous agissons de manière bienveillante. Pourtant, un grand jardin, pour un chien, s’il est seul, c’est surtout une grande prison ! Et prendre un deuxième chien pour ne pas qu’il soit seul, cela peut créer des problèmes supplémentaires ! Offrir des conditions de vie satisfaisantes à son animal de compagnie, c’est l’accepter dans sa différence et prendre conscience que ce qui est bon pour nous ne l’est pas forcément pour lui. Appréhender le comportement de l’animal permet donc de casser certaines idées reçues.

                      </p>
                    </q-card-section>
                  </q-card>
                </q-expansion-item>

                <q-separator />

                <q-expansion-item
                  group="faq"
                  label="Que dit la loi en Région Wallonne ? "
                >
                  <q-card>
                    <q-card-section>
                      <p>
                        Le 1er juillet 2022 est entré en vigueur le nouveau « Permis de détention » d’un animal en Région wallonne, dans la lignée du Code du bien-être animal qui avait été adopté en 2018. Le Gouvernement wallon prévoit de nouveaux moyens répressifs pour lutter contre la maltraitance animale.  <br><br>

                        Le permis de détention poursuit un double objectif :  <br><br>
                        - Éviter les achats impulsifs, qui aboutissent trop souvent à des abandons, en imposant de facto une démarche de réflexion avant d’accueillir un animal.  <br>
                        - Mieux lutter contre les maltraitants récidivistes, en limitant leurs possibilités d'adoption ou d'acquisition. <br><br>

                        Tout particulier qui souhaite acheter ou adopter un animal de compagnie en se rendant chez un professionnel devra montrer un document prouvant qu’il n’a pas été déchu de son permis de détention. Ce document sera livré sur simple demande par les administrations communales wallonnes.  <br>
                        Les animaux concernés par ce permis sont les chiens, les chats, les chevaux, les oiseaux, les hamsters, les souris, les poissons, les poules, les tortues, les reptiles et NAC, les lapins, les furets et les chèvres. N’hésitez pas à consulter les nouvelles réglementations sur le site de la Région Wallonne, notamment pour connaître les listes positives pour chaque espèce, à savoir celles qui sont autorisées pour l’adoption. <br>
                        Les « listes positives » permettent aux citoyens de privilégier des espèces qui sont mieux adaptées à la vie en captivité, dans un objectif de bien-être pour l’animal.
                      </p>
                    </q-card-section>
                  </q-card>
                </q-expansion-item>


              </q-list>
        </div>
      </div>
    </section>

    <Contact/>


  </div>
</template>

<script>
import { Autoplay, EffectFade } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';

import Contact from '../components/ContactComp.vue'

export default {
  name: 'HomeView',
  components: {
    Swiper,
    SwiperSlide,
    Contact
  },
  data () {
    return  {
      imgLapin: require('../assets/images/Header-lapin.jpg')
    }
  },
  setup() {
    return {
      modules: [Autoplay, EffectFade],
    };
  },
}
</script>

<style type="text/css">

  .slider-head {
    height: 100%;
  }

  .slider-head .swiper-slide {
    background-size: cover;
    background-position: center;
  }

  .permis-general {
    background-image: url('../assets/images/accueil-permis-general.jpg');
  }

  .mieux-connaitre {
    background-image: url('../assets/images/mieux-connaitre.jpg');
  }

  .pense-bete-TV-image {
    background-image: url('../assets/images/pense-bete-TV-image.jpg');
  }

  .prof-photo {
    background-image: url('../assets/images/prof-photo.jpg');
  }

  .bg-like-ou-pas {
    background-image: url('../assets/images/bg-like-ou-pas.jpg');
  }

  .illu-lapin {
    position: relative;
    margin-top: -100px;
    right: -100%;
    pointer-events: none;
  }

  .illu-chien {
    position: relative;
    margin-top: -125px;
    left: -70%;
    pointer-events: none;
  }



  .permis-gallery > div {
    width: 50%;
    box-sizing: border-box;
    padding: 10px;
  }

  .permis-gallery > div > img {
    width: 100%;
    display: block;
  }


  @media (orientation: portrait) {
    .illu-lapin {
      position: relative;
      margin-top: -50px;
      right: -50%;
    }
    .illu-chien {
      margin-top: -85px;
      left: -50%;
      pointer-events: none;
    }
  }

</style>

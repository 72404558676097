<template>
	<section class="contact-bg" style="background-color: #f9f5f1;">
		<div class="container">
			<div class="bg-contact"></div>
			<div class="col-center padding">
				<div class="form-container">
					<h2>Contactez-nous</h2> 
					<p v-if="sent">Votre message a bien été envoyé.</p>
					<p v-if="sent_error" class="error">
						Une erreur est survenue, votre message n'a pas pu être envoyé.
						<br><br>
						<q-btn @click="sent_error = false" label="Réessayer" />
					</p>
					<q-form
						@submit="onSubmit"
						class="q-gutter-md"
						v-if="!sent && !sent_error"
						
					>
						<q-input
							outlined 
							v-model="form.nom"
							label="Nom *"
							lazy-rules
							:rules="[ val => val && val.length > 0 || 'Entre ton nom']"
							:disable="disabled"
						/>

						<q-input
							outlined 
							type="email"
							v-model="form.email"
							label="Email *"
							lazy-rules
							:rules="[ val => val && val.length > 0 || 'Entre ton adresse email']"
							:disable="disabled"
						/>

						<q-input
							outlined 
							type="textarea"
							v-model="form.message"
							label="Message *"
							lazy-rules
							:rules="[ val => val && val.length > 0 || 'Entre ton message']"
							:disable="disabled"
						/>


						<q-checkbox 
							v-validate="'required'"
							v-model="accept" 
							required
							lazy-rules
							:rules="[ val == true ]"
							label="J'accepte les conditions d'utilisation"
							:disable="disabled"
							/>

						<div v-if="accept_error && accept == false" class="message error">
							Veuillez accepter les conditions d'utilisation
						</div>

						<input type="hidden" name="skey" v-model="form.skey">
						<div>
							<q-btn label="Envoyer" type="submit" color="primary" :disabled="disabled"/>
						</div>
					</q-form>
				</div>
				<img class="illu-chien" src="../assets/images/illu-chien.png">

			</div>
		</div>
	</section>
</template>

<script>
	import $ from "jquery"

	export default {
		data () {
			return	{
				form : {
					nom: null,
					email: null,
					message: null,
					skey: 'afn.qrg8MZH9hkz8hwh',
				},		
				accept: false,
				accept_error: false,
				sent: false,
				sent_error: false,
				disabled: false,
			}
		},
		methods : {
			onSubmit(){
				if( this.accept == false )
				{
					this.accept_error = true
					return;
				}

				this.disabled = true

				$.ajax({
					url: /*'https://dev-v2.pense-bete.be' +*/ '/app_link.php',
					type: 'POST',
					//dataType: 'default: Intelligent Guess (Other values: xml, json, script, or html)',
					data: this.form,
				})
				.done( (result) => {
					if( result == "ok")
					{
						this.sent = true
						this.disabled = false
					}
					else
					{
						this.sent_error = true
						this.disabled = false
					}
					
				})
				.fail( () => {
					this.sent_error = true
					this.disabled = false
				})
				
			}
		}
	}
</script>

<style type="text/css">
	.contact-bg {
		height: 800px;
		background-size: cover;
		background-position: center;
		overflow: hidden;
	}

	.form-container {
		position: relative;
		background-color: white;
		border-radius: 15px;
		padding: 20px;
	}

	.bg-contact {
		position: absolute;
		height: 800px;
		width: 50%;
		left: 50%;
		background-image: url('../assets/images/accueil-mail-01.jpg');
		background-size: cover;
		background-position: center;
		border-radius: 800px 0 0 800px;
	}

	@media (orientation: portrait) { 
		.form-container {
			background-color: rgba(255, 255, 255, 0.95);
			margin-top: 50px;
		}
		.bg-contact {
			height: 400px;
			width: 100%;
			left: 00%;
		}
	}

	.contact-bg .error {
		color: #C10015;
	}
	.contact-bg .message.error {
		font-size: 12px;
		
		margin-top: 0;
	}
</style>